


























import { Component, Prop, Vue } from 'vue-property-decorator';
import ld from 'lodash';
import { lessonPropertyDefinitions } from '@/constants';
import CommonUtils from '@/utils/common-utils';

@Component
export default class LessonDiff extends Vue {
    @Prop({
      required: true,
      type: Object,
      default: () => {
        return {};
      }
    })
    originalLesson: any;

    @Prop({
      required: true,
      type: Object,
      default: () => {
        return {};
      }
    })
    updatedLesson: any;

    get lessonPropertyDefinitions() {
      return lessonPropertyDefinitions;
    }

    get updatedFields() {
      return ld.filter(lessonPropertyDefinitions, (pd) => {
        if (this.hasNothing(this.originalLesson, pd) && this.hasNothing(this.updatedLesson, pd)) {
          return false;
        }
        return !ld.isEqual(this.getValue(this.originalLesson, pd), this.getValue(this.updatedLesson, pd))
      }).map(pd => pd.key);
    }

    getValue(obj: any, propertyDefinition: any) {
      const key = propertyDefinition.key;
      const value = propertyDefinition.default;
      if (obj) {
        if (propertyDefinition.type === 'number') {
          return CommonUtils.getOrDefaultTo(+obj[key], value);
        } else if (propertyDefinition.type === 'array') {
          if (CommonUtils.isNotEmpty(obj[key])) {
            if (typeof obj[key] === 'string') {
              return [obj[key]];
            } else {
              return obj[key].map((v: any) => '' + v);
            }
          }
          return [];
        } else {
          return CommonUtils.getOrDefaultTo(obj[key], value);
        }
      }
      return undefined;
    }

    hasNothing(obj: any, propertyDefinition: any) {
      const type = propertyDefinition.type;
      const value = this.getValue(obj, propertyDefinition);
      if (value) {
        if (type === 'string') {
          return CommonUtils.hasNoText(value);
        } else if (type === 'array') {
          return CommonUtils.isEmpty(value);
        } else {
          return value.length === 0
        }
      }
      return true;
    }
}
