



























































































import LessonServices from '@/services/lesson-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LessonDiff from '@/components/lessons/actions/LessonDiff.vue';
import ld from 'lodash';
import AppError from '@/errors';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import moment from 'moment';
import { infiniteScrollDelay } from '@/constants';

const settings = namespace('settings');
const changehistory = namespace('changehistory');
const lessons = namespace('lessons');
const plans = namespace('plans');

@Component({
  mixins: [TableResizeMixin, PageLifeCycleMixin],
  components: {
    LessonDiff
  }
})
export default class Teachers extends Vue {
  @settings.Getter('getSettings')
  userInfo!: any;

  @settings.Getter('getUserType')
  userType!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter
  highContrastMode!: boolean;

  @changehistory.State
  changeHistory!: Array<any>;

  @changehistory.Action
  loadChangeHistory!: () => Promise<any>;

  @lessons.Action
  restore!: (params: any) => Promise<any>;

  @lessons.Action
  undoBumpForward!: (params?: any) => Promise<any>;

  @lessons.Action
  undoBumpBackward!: (params?: any) => Promise<any>;

  @lessons.Action
  undoDeleteLesson!: (params?: any) => Promise<any>;

  @plans.Mutation
  setResetOnly!: (resetOnly: boolean) => void;

  listLoading = false
  searchText = '';
  tableHeight!: any;
  localShowDiff = false;
  localChangeHistoryPayload: any = {};

  infiniteScrollList:any[] = [];
  isLoadingMore = false;
  canLoadMore = true;
  initialLoad = true;
  pageNumber = 0;
  pageCount = 0;
  listEndObserver:any = undefined;

  $refs!: {
    table: any,
    sentinel: any,
    scrollContainer:any
  }

  get changeHistoryHeaders() {
    return [
      {
        text: this.$t('typeLabel'),
        align: 'start',
        value: 'revisionType'
      },
      {
        text: this.$t('classNameLabel'),
        align: 'start',
        value: 'parentName'
      },
      {
        width: 160,
        text: this.$t('lessonDateLabel'),
        align: 'start',
        value: 'oPayload.customDate'
      },
      {
        text: this.$t('updatedFieldsLabel'),
        align: 'start',
        value: 'formattedFields'
      },
      {
        width: 225,
        text: this.$t('lastUpdateLabel'),
        value: 'revisionDate'
      },
      {
        width: 300,
        sortable: false,
        value: 'action'
      }
    ];
  }

  get formatToShow() {
    return DateTimeUtils.formatToShow
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get isTrue() {
    return CommonUtils.isTrue;
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get isMobileMode() {
    return this.$vuetify.breakpoint.xs;
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get filteredChangeHistory() {
    return this.changeHistory.map(c => {
      const item = ld.cloneDeep(c);
      item.formattedFields = this.getFields(item.fields || '');
      item.initStateFromPayload = true;
      item.oPayload = JSON.parse(item.payload || '{}');
      if (item.revisionType === 'UNDOBUMPLESSON') {
        if (CommonUtils.isTrue(item.oPayload.deleteCustomDay)) {
          item.revisionType = 'UNDOREMOVECLASSFROMDAY';
        }
      } else if (item.revisionType === 'UNDODELETELESSON') {
        if (CommonUtils.isTrue(item.oPayload.deleteCustomDay)) {
          item.revisionType = 'UNDOREMOVECLASSFROMDAY';
        }
      }
      return item;
    });
  }

  get oldData() {
    const oldLesson = this.localChangeHistoryPayload.oldLesson;
    return LessonServices.createRequest(oldLesson);
  }

  get updatedData() {
    const updated = ld.cloneDeep(this.localChangeHistoryPayload);
    delete updated.oldLesson;
    return updated;
  }

  get lastActionId() {
    const allActions = this.filteredChangeHistory.filter(h => h.revisionType !== 'LESSON');
    return (allActions[0] || { id: 0 }).id;
  }

  get chipFontWeight() {
    if (this.highContrastMode) {
      return 550;
    } else {
      return 500;
    }
  }

  showDiff(item: any) {
    this.localShowDiff = true;
    if (CommonUtils.hasText(item.payload)) {
      this.localChangeHistoryPayload = JSON.parse(item.payload);
      const oldLesson = this.localChangeHistoryPayload.oldLesson;
      if (CommonUtils.hasText(oldLesson)) {
        this.localChangeHistoryPayload.oldLesson = JSON.parse(oldLesson);
      }
    } else {
      this.localChangeHistoryPayload = {};
    }
  }

  get formatToDisplay() {
    return DateTimeUtils.formatToDisplay;
  }

  get formatTimeStampToDisplay() {
    return DateTimeUtils.formatTimestampToDisplay;
  }

  initializeInfiniteScroll() {
    const that = this;
    this.resetInfiniteScrollData();
    if (CommonUtils.isNotEmpty(that.filteredChangeHistory)) {
      const scrollContainer = that.$refs.scrollContainer;
      const tableRowHeight = 48;

      const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
      this.tableHeight = scrollContainer.clientHeight;
      that.pageCount = Math.round(that.filteredChangeHistory.length / itemsPerScreen);
      that.pageNumber = 0;
      that.setInfiniteScrollInitialData();
    } else {
      that.initialLoad = false;
      that.canLoadMore = false;
    }
  }

  resetInfiniteScrollData() {
    this.infiniteScrollList = [];

    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }

    this.isLoadingMore = false;
    this.canLoadMore = true;

    this.initialLoad = true;
    this.pageNumber = 0;
    this.pageCount = 0;
    this.listEndObserver = undefined;
  }

  setInfiniteScrollInitialData() {
    const that = this;
    this.fetchMoreItems().then((items:any) => {
      that.infiniteScrollList.push(...items);
      that.initialLoad = false;
      // wait for initial list to render and then set up observer
      that.$nextTick(() => {
        that.setUpInterSectionObserver();
      })
    });
  }

  setUpInterSectionObserver() {
    const options = {
      root: this.$refs.scrollContainer,
      rootMargin: '0px 0px 0px 0px'
    };
    this.listEndObserver = new IntersectionObserver(
      this.handleIntersection,
      options
    );

    this.listEndObserver.observe(this.$refs.sentinel);
  }

  handleIntersection([entry]:any) {
    if (entry.isIntersecting) {
      console.log('sentinel intersecting');
    }
    if (entry.isIntersecting && this.canLoadMore && !this.isLoadingMore) {
      this.loadMore();
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      const items:any = await this.fetchMoreItems();
      console.log('loaded page ' + this.pageNumber);
      this.pageNumber++;
      this.infiniteScrollList.push(...items);
    } catch (error) {
      console.log('Reached end of page', error);
      console.log('total items in the page ' + this.infiniteScrollList.length);
      this.canLoadMore = false;
    } finally {
      this.isLoadingMore = false;
    }
  }

  fetchMoreItems() {
    const that = this;
    const scrollContainer = that.$refs.scrollContainer;
    const tableRowHeight = 48;

    const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
    return new Promise((resolve, reject) => {
      const data:any = [];
      let indexOfLastData = 0;
      if (CommonUtils.isNotEmpty(that.infiniteScrollList)) {
        indexOfLastData = that.filteredChangeHistory.findIndex((l:any) => {
          return l.id === that.infiniteScrollList[that.infiniteScrollList.length - 1].id
        });
        indexOfLastData++;
      }
      if (((that.filteredChangeHistory.length - indexOfLastData) < itemsPerScreen)) {
        for (let index = indexOfLastData; index <= that.filteredChangeHistory.length - 1; index++) {
          data.push(that.filteredChangeHistory[index]);
        }
      } else {
        for (let i = 1; i <= itemsPerScreen; i++) {
          data.push(that.filteredChangeHistory[indexOfLastData]);
          indexOfLastData++;
        }
      }

      setTimeout(() => {
        if (CommonUtils.isNotEmpty(data)) {
          resolve(data);
        } else {
          reject(new Error('No more items to load at the Bottom'));
        }
      }, infiniteScrollDelay);
    });
  }

  undoAction(item: any) {
    const type = item.revisionType;
    this.listLoading = true;
    Promise.resolve().then(() => {
      if (type === 'LESSON') {
        return this.restore(item);
      } else if (type === 'EXTENDLESSON') {
        return this.undoBumpForward({ ...item, bumpedLessonId: item.parentId, action: 'UNDOEXTENDLESSON' });
      } else if (type === 'BUMPLESSON') {
        return this.undoBumpForward({ ...item, bumpedLessonId: item.parentId, action: 'UNDOBUMPLESSON' });
      } else if (type === 'BUMPBACKLESSON') {
        return this.undoBumpBackward({ ...item, bumpedLessonId: item.parentId });
      } else if (type === 'DELETELESSON') {
        return this.undoDeleteLesson({ ...item, bumpedLessonId: item.parentId });
      } else if (type === 'REMOVECLASSFROMDAY') {
        if (CommonUtils.isTrue(item.oPayload.shiftLessons)) {
          return this.undoBumpForward({ ...item, bumpedLessonId: item.parentId, action: 'UNDOBUMPLESSON', deleteCustomDay: true });
        } else {
          return this.undoDeleteLesson({ ...item, bumpedLessonId: item.parentId, deleteCustomDay: true });
        }
      } else {
        return Promise.reject(new AppError('contactSupport'));
      }
    }).then(() => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg27') as string);
      }
      return this.initialize(false);
    }).finally(CommonUtils.hideLoading);
  }

  getFields(fields: string) {
    const arrFields = fields.split(',');
    return arrFields.join(', ');
  }

  async initialize(initialLoad:boolean) {
    initialLoad ? CommonUtils.showLoading() : this.listLoading = true;
    return this.loadChangeHistory().then(() => {
      this.listLoading = false;
      CommonUtils.hideLoading();
      this.initializeInfiniteScroll();
      return Promise.resolve();
    });
  }

  created() {
    this.$nextTick(() => {
      this.initialize(true);
      this.setResetOnly(true);
    })
  }

  beforeDestroy() {
    this.setResetOnly(false);
  }

  timezoneDate(date:any) {
    return this.formatTimeStampToDisplay(moment.utc(date, 'MM/DD/YYYY HH:mm:ss').local().format('MM/DD/YYYY HH:mm:ss'), true);
  }
}
