var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    { staticClass: "change-history", attrs: { color: "#ffffff" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "rounded-0 mt-5", attrs: { flat: "" } },
                [
                  _c(
                    "div",
                    { ref: "scrollContainer", staticClass: "scroll-container" },
                    [
                      _c("v-data-table", {
                        ref: "table",
                        attrs: {
                          "multi-sort": false,
                          loading: _vm.listLoading,
                          "loading-text": _vm.$t("listLoadingMsg"),
                          headers: _vm.changeHistoryHeaders,
                          items: _vm.infiniteScrollList,
                          "item-key": "id",
                          height: _vm.tableHeight,
                          search: _vm.searchText,
                          "fixed-header": "",
                          "hide-default-footer": "",
                          "disable-pagination": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "body",
                            fn: function ({ items }) {
                              return [
                                _c(
                                  "tbody",
                                  {
                                    ref: "listContainer",
                                    staticClass: "list-container",
                                  },
                                  [
                                    _vm._l(items, function (item, index) {
                                      return _c(
                                        "tr",
                                        {
                                          key: index,
                                          class: _vm.mobileTableClass,
                                        },
                                        [
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass,
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .changeHistoryHeaders[0]
                                                            .text
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.revisionType ==
                                              "EXTENDLESSON"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "red--text text-lighten-3",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#fee9e7",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "extendLessonLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : item.revisionType ==
                                                  "UNDOEXTENDLESSON"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "red--text text-lighten-3",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#fee9e7",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("undoLabel") +
                                                            " " +
                                                            _vm.$t(
                                                              "extendLessonLabel"
                                                            )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : item.revisionType ==
                                                  "BUMPLESSON"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "blue--text text-lighten-2",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#e5f3fe",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "bumpForwardLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : item.revisionType ==
                                                  "UNDOBUMPLESSON"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "blue--text text-lighten-2",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#e5f3fe",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("undoLabel") +
                                                            " " +
                                                            _vm.$t(
                                                              "bumpForwardLabel"
                                                            )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : item.revisionType ==
                                                  "BUMPBACKLESSON"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "teal--text text-lighten-3",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#e1f3f1",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "bumpBackwardLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : item.revisionType ==
                                                  "UNDOBUMPBACKLESSON"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "teal--text text-lighten-3",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#e1f3f1",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("undoLabel") +
                                                            " " +
                                                            _vm.$t(
                                                              "bumpBackwardLabel"
                                                            )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : item.revisionType ==
                                                  "DELETELESSON"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "teal--text text-lighten-3",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#e1f3f1",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "deleteLessonLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : item.revisionType ==
                                                  "REMOVECLASSFROMDAY"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "teal--text text-lighten-3",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#e1f3f1",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "noClassDayLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : item.revisionType ==
                                                  "UNDOREMOVECLASSFROMDAY"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "teal--text text-lighten-3",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#e1f3f1",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("undoLabel") +
                                                            " " +
                                                            _vm.$t(
                                                              "noClassDayLabel"
                                                            )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : item.revisionType ==
                                                  "UNDODELETELESSON"
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "teal--text text-lighten-3",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        small: "",
                                                        color: "#e1f3f1",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("undoLabel") +
                                                            " " +
                                                            _vm.$t(
                                                              "deleteLessonLabel"
                                                            )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "indigo--text text-lighten-4",
                                                      style: {
                                                        cursor: _vm.pointer,
                                                        "font-weight":
                                                          _vm.chipFontWeight,
                                                      },
                                                      attrs: {
                                                        label: "",
                                                        color: "#e8ebf6",
                                                        small: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "updateLessonLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass,
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .changeHistoryHeaders[1]
                                                            .text
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.parentName)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass,
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .changeHistoryHeaders[2]
                                                            .text
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatToDisplay(
                                                        item.oPayload
                                                          .customDate,
                                                        true
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass,
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .changeHistoryHeaders[3]
                                                            .text
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.formattedFields)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass,
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .changeHistoryHeaders[4]
                                                            .text
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.timezoneDate(
                                                        item.revisionDate
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c("div", {
                                                    class:
                                                      _vm.mobileHeaderClass,
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [
                                                  _vm.isLinkedLessonRevision(
                                                    item
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mx-3 hover-button-primary",
                                                          attrs: {
                                                            text: "",
                                                            small: "",
                                                            color:
                                                              _vm.contentBreakpoint ===
                                                              "xs"
                                                                ? "primary"
                                                                : "transparent",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.unlinkAction(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "unlinkLabel"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : [
                                                        _vm.isLessonRevision(
                                                          item
                                                        ) ||
                                                        (item.id ===
                                                          _vm.lastActionId &&
                                                          [
                                                            "EXTENDLESSON",
                                                            "BUMPLESSON",
                                                            "BUMPBACKLESSON",
                                                            "DELETELESSON",
                                                            "REMOVECLASSFROMDAY",
                                                          ].includes(
                                                            item.revisionType
                                                          ))
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-3 hover-button-primary",
                                                                attrs: {
                                                                  text: "",
                                                                  small: "",
                                                                  color:
                                                                    _vm.contentBreakpoint ===
                                                                    "xs"
                                                                      ? "primary"
                                                                      : "transparent",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      $event.preventDefault()
                                                                      return _vm.undoAction(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "undoLabel"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.isLessonRevision(
                                                          item
                                                        )
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-3 hover-button-error",
                                                                attrs: {
                                                                  text: "",
                                                                  small: "",
                                                                  color:
                                                                    _vm.contentBreakpoint ===
                                                                    "xs"
                                                                      ? "error"
                                                                      : "transparent",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      $event.preventDefault()
                                                                      return _vm.showDiff(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "showDiffLabel"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    _vm.canLoadMore &&
                                    items.length !==
                                      _vm.infiniteScrollList.length &&
                                    (_vm.searchText === null ||
                                      _vm.searchText === "")
                                      ? _c("tr", [
                                          _c("td"),
                                          _c("td"),
                                          _c("td"),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                attrs: {
                                                  align: "center",
                                                  justify: "center",
                                                },
                                              },
                                              [
                                                _vm.isLoadingMore ||
                                                _vm.initialLoad
                                                  ? _c("v-progress-circular", {
                                                      attrs: {
                                                        color: "primary",
                                                        indeterminate: "",
                                                      },
                                                    })
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "scrollDownToLoadMoreLabel"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fal fa-arrow-down"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("td"),
                                          _c("td"),
                                        ])
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _c("div", {
                                  ref: "sentinel",
                                  staticClass: "sentinel",
                                  style: {
                                    "margin-bottom":
                                      _vm.canLoadMore &&
                                      !_vm.isLoadingMore &&
                                      _vm.filteredChangeHistory.length !==
                                        _vm.infiniteScrollList.length
                                        ? "70px"
                                        : "",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: {
            input: {},
            withApply: false,
            expandable: false,
            maxWidth: 900,
            withActions: false,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("lessonUpdateLabel")))]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.localShowDiff,
            callback: function ($$v) {
              _vm.localShowDiff = $$v
            },
            expression: "localShowDiff",
          },
        },
        [
          _c("lesson-diff", {
            attrs: {
              originalLesson: _vm.oldData,
              updatedLesson: _vm.updatedData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }