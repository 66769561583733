var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-simple-table", {
    attrs: { "fixed-header": "", height: "500px" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text-left", attrs: { width: "170px" } },
                  [_vm._v(_vm._s(_vm.$t("lessonAttributeLabel")))]
                ),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("originalLabel"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("updatedLabel"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.lessonPropertyDefinitions, function (definition, i) {
                return _c(
                  "tr",
                  {
                    key: i,
                    class: {
                      "yellow lighten-5": _vm.updatedFields.includes(
                        definition.key
                      ),
                    },
                  },
                  [
                    _c("td", [
                      _c(
                        "div",
                        {
                          class: {
                            "font-weight-bold": _vm.updatedFields.includes(
                              definition.key
                            ),
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(definition.key.toUpperCase()) + " "
                          ),
                          _vm.updatedFields.includes(definition.key)
                            ? [_vm._v("*")]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _c("td", {
                      staticClass: "text-center",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getValue(_vm.originalLesson, definition)
                        ),
                      },
                    }),
                    _c("td", {
                      staticClass: "text-center",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getValue(_vm.updatedLesson, definition)
                        ),
                      },
                    }),
                  ]
                )
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }